import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      {/* Header with Identium text */}
      <header className="header">
        <h1>Identium</h1>
      </header>

      {/* Background video */}
      <video autoPlay loop muted playsInline className="background-video">
        <source src="./bg-movie2.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* Footer */}
      <footer className="footer">
        <h1>Decentralize, or Die Trying<br /> Stay tuned for updates.</h1>
        <p>&copy; 2024 Identium. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default App;
